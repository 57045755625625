
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { diagramFilters } from '@/modules/model/helpers/filters'
import { ModelModule } from '@/modules/model/store'

import ListItem from './list-item.vue'

@Component({
  components: {
    ListItem
  },
  name: 'DiagramFilter'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)

  searchTerm = ''
  searchTermModel = ''

  filters = diagramFilters

  get filtersFuzzy () {
    if (this.searchTerm) {
      const fuse = new Fuse(this.filters, {
        keys: [
          'name'
        ],
        threshold: 0.3
      })
      return fuse.search(this.searchTerm).map(o => o.item)
    } else {
      return this.filters
    }
  }

  opened () {
    this.searchTerm = ''
    this.searchTermModel = ''
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 300)

  setSearchTerm (term: string) {
    this.searchTerm = term
  }
}
