
import { TAG_COLOR_ACTIVE, TAG_COLOR_BACKGROUND, TAG_COLOR_INACTIVE } from '@icepanel/app-canvas'
import { CatalogTechnology } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import TagChip from '@/modules/tag/components/tag.vue'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../../helpers/analytics'

@Component({
  components: {
    TagChip
  },
  name: 'CatalogTechnologyFilterListItem'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly technology!: CatalogTechnology

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get includeTechnology () {
    return this.$queryArray('filter_include')
  }

  get excludeTechnology () {
    return this.$queryArray('filter_exclude')
  }

  get opacity () {
    return (this.includeTechnology.length || this.excludeTechnology.length) && !this.included && !this.excluded ? 0.5 : 1
  }

  get colorMap () {
    return (this.includeTechnology.length || this.excludeTechnology.length) && (this.included || this.excluded) ? TAG_COLOR_ACTIVE : TAG_COLOR_INACTIVE
  }

  get colorBackgroundMap () {
    return TAG_COLOR_BACKGROUND
  }

  get count () {
    return Object.values(this.modelModule.objects || {}).filter(o => o.technologies[this.technology.id]).length
  }

  get image () {
    return iconUrlForTheme(this.technology)
  }

  get included () {
    return this.includeTechnology.includes(this.technology.id)
  }

  get excluded () {
    return this.excludeTechnology.includes(this.technology.id)
  }

  toggleTechnologyInclude () {
    if (this.included) {
      this.$replaceQuery({
        filter_exclude: this.$removeQueryArray(this.technology.id),
        filter_include: this.$removeQueryArray(this.technology.id)
      })
    } else {
      this.$replaceQuery({
        filter_exclude: this.$removeQueryArray(this.technology.id),
        filter_include: this.$unionQueryArray(this.technology.id)
      })

      analytics.catalogTechnologyFilterInclude.track(this, {
        catalogTechnologyColor: this.technology.color,
        catalogTechnologyName: this.technology.name,
        catalogTechnologyNameShort: this.technology.nameShort,
        catalogTechnologyProvider: this.technology.provider,
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  toggleTechnologyExclude () {
    if (this.excluded) {
      this.$replaceQuery({
        filter_exclude: this.$removeQueryArray(this.technology.id),
        filter_include: this.$removeQueryArray(this.technology.id)
      })
    } else {
      this.$replaceQuery({
        filter_exclude: this.$unionQueryArray(this.technology.id),
        filter_include: this.$removeQueryArray(this.technology.id)
      })

      analytics.catalogTechnologyFilterExclude.track(this, {
        catalogTechnologyColor: this.technology.color,
        catalogTechnologyName: this.technology.name,
        catalogTechnologyNameShort: this.technology.nameShort,
        catalogTechnologyProvider: this.technology.provider,
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
