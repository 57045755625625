
import { ModelObjectTechnology } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { ModelModule } from '@/modules/model/store'

import ListItem from './list-item.vue'

@Component({
  components: {
    ListItem
  },
  name: 'CatalogTechnologyFilter'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)

  searchTerm = ''
  searchTermModel = ''

  get technologies () {
    return Object
      .values(this.modelModule.objects)
      .reduce<Record<string, ModelObjectTechnology>>((p, c) => ({
        ...p,
        ...c.technologies
      }), {})
  }

  get technologyFuzzy () {
    const technologies = Object.values(this.technologies)
    if (this.searchTerm) {
      const fuse = new Fuse(technologies, {
        keys: [
          'name',
          'nameShort',
          'provider'
        ],
        threshold: 0.3
      })
      return fuse.search(this.searchTerm).map(o => o.item)
    } else {
      return technologies
    }
  }

  opened () {
    this.searchTerm = ''
    this.searchTermModel = ''
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 300)

  setSearchTerm (term: string) {
    this.searchTerm = term
  }
}
