import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { modelObjectType } from '@/modules/model/helpers/analytics-properties'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

import { diagramGroupModelType, diagramType } from './analytics-properties'

/**
 * Screens
 */

export const diagramsScreen = new analytics.Event({
  displayName: 'Diagrams screen',
  name: 'diagrams_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})

/**
 * Dialogs
 */

export const diagramDeleteDialog = new analytics.Event({
  displayName: 'Diagram delete dialog',
  name: 'diagram_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const diagramGroupDeleteDialog = new analytics.Event({
  displayName: 'Diagram group delete dialog',
  name: 'diagram_group_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})
export const diagramGroupDiagramsDialog = new analytics.Event({
  displayName: 'Diagram group diagrams dialog',
  name: 'diagram_group_diagrams_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  diagramGroupModelType,
  landscapeId,
  organizationId
})

/**
 * Menus
 */

export const diagramFilterMenu = new analytics.Event({
  displayName: 'Diagram filter menu',
  name: 'diagram_filter_menu'
}, {
  landscapeId,
  organizationId
})

/**
 * Events
 */

export const diagramCreate = new analytics.Event({
  displayName: 'Diagram create',
  name: 'diagram_create'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const diagramDelete = new analytics.Event({
  displayName: 'Diagram delete',
  name: 'diagram_delete'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const diagramGroupCreate = new analytics.Event({
  displayName: 'Diagram group create',
  name: 'diagram_group_create'
}, {
  landscapeId,
  organizationId
})
export const diagramGroupDelete = new analytics.Event({
  displayName: 'Diagram group delete',
  name: 'diagram_group_delete'
}, {
  landscapeId,
  organizationId
})
export const diagramResetCameraPosition = new analytics.Event({
  displayName: 'Diagram reset camera position',
  name: 'diagram_reset_camera_position'
}, {
  landscapeId,
  organizationId
})
export const diagramObjectZoom = new analytics.Event({
  displayName: 'Diagram object zoom',
  name: 'diagram_object_zoom'
}, {
  diagramType,
  landscapeId,
  modelObjectType,
  organizationId
})
export const diagramMove = new analytics.Event({
  displayName: 'Diagram move',
  name: 'diagram_move'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const diagramObjectCreate = new analytics.Event({
  displayName: 'Diagram object create',
  name: 'diagram_object_create'
}, {
  diagramType,
  landscapeId,
  modelObjectType,
  organizationId
})
export const diagramObjectAdd = new analytics.Event({
  displayName: 'Diagram object add',
  name: 'diagram_object_add'
}, {
  diagramType,
  landscapeId,
  modelObjectType,
  organizationId
})
export const diagramExistsFilter = new analytics.Event({
  displayName: 'Diagram exists filter',
  name: 'diagram_exists_filter'
}, {
  landscapeId,
  organizationId
})
export const diagramExistsFilterExclude = new analytics.Event({
  displayName: 'Diagram exists filter exclude',
  name: 'diagram_exists_filter_exclude'
}, {
  landscapeId,
  organizationId
})

/**
 * Hidden
 */

export const diagramMoveDomainHidden = new analytics.Event({
  displayName: 'Diagram move domain',
  hidden: true,
  name: 'diagram_move_domain'
}, {
  diagramType,
  landscapeId,
  organizationId
})
