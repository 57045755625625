import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

import {
  flowStepAlternatePathCount,
  flowStepCount,
  flowStepOutgoingCount,
  flowStepParallelPathCount,
  flowStepReplyCount,
  flowStepSelfActionCount
} from './analytics-properties'

/**
 * Screens
 */

export const flowEditorScreen = new analytics.Event({
  displayName: 'Flow editor screen',
  name: 'flow_editor_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const flowsScreen = new analytics.Event({
  displayName: 'Flows screen',
  name: 'flows_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})

/**
 * Dialogs
 */

export const flowDeleteDialog = new analytics.Event({
  displayName: 'Flow delete dialog',
  name: 'flow_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})

/**
 * Menu
 */

export const flowPickerMenu = new analytics.Event({
  displayName: 'Flow picker menu',
  name: 'flow_picker_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})

/**
 * Events
 */

export const flowCopyText = new analytics.Event({
  displayName: 'Flow copy text',
  name: 'flow_copy_text'
}, {
  flowStepAlternatePathCount,
  flowStepCount,
  flowStepOutgoingCount,
  flowStepParallelPathCount,
  flowStepReplyCount,
  flowStepSelfActionCount,
  landscapeId,
  organizationId
})
export const flowCopyCode = new analytics.Event({
  displayName: 'Flow copy code',
  name: 'flow_copy_code'
}, {
  flowStepAlternatePathCount,
  flowStepCount,
  flowStepOutgoingCount,
  flowStepParallelPathCount,
  flowStepReplyCount,
  flowStepSelfActionCount,
  landscapeId,
  organizationId
})
export const flowExistsFilter = new analytics.Event({
  displayName: 'Flow exists filter',
  name: 'flow_exists_filter'
}, {
  landscapeId,
  organizationId
})
export const flowExistsFilterExclude = new analytics.Event({
  displayName: 'Flow exists filter exclude',
  name: 'flow_exists_filter_exclude'
}, {
  landscapeId,
  organizationId
})

/**
 * Hidden
 */
export const flowOpenSequenceDiagramHidden = new analytics.Event({
  displayName: 'Flow open sequence diagram',
  hidden: true,
  name: 'flow_open_sequence_diagram'
}, {
  flowStepAlternatePathCount,
  flowStepCount,
  flowStepOutgoingCount,
  flowStepParallelPathCount,
  flowStepReplyCount,
  flowStepSelfActionCount,
  landscapeId,
  organizationId
})
