
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import * as flowAnalytics from '@/modules/flow/helpers/analytics'
import { LandscapeModule } from '@/modules/landscape/store'
import { IFilterType } from '@/modules/model/helpers/filters'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  name: 'DiagramFilterListItem'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly filter!: IFilterType

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get filterType () {
    return this.$queryArray('filter')
  }

  get filterExcludeType () {
    return this.$queryArray('filter_exclude')
  }

  get opacity () {
    return (this.filterType.length || this.filterExcludeType.length) && !this.filtered && !this.excluded ? 0.5 : 1
  }

  get icon () {
    return this.filter.icon
  }

  get filtered () {
    return this.filterType.includes(this.filter.id)
  }

  get excluded () {
    return this.filterExcludeType.includes(this.filter.id)
  }

  toggleFilter () {
    if (this.filtered) {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.filter.id),
        filter_exclude: this.$removeQueryArray(this.filter.id)
      })
    } else {
      this.$replaceQuery({
        filter: this.$unionQueryArray(this.filter.id),
        filter_exclude: this.$removeQueryArray(this.filter.id)
      })

      if (this.filter.id === 'diagram-exists') {
        analytics.diagramExistsFilter.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId]
        })
      } else if (this.filter.id === 'flow-exists') {
        flowAnalytics.flowExistsFilter.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    }
  }

  toggleExclude () {
    if (this.excluded) {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.filter.id),
        filter_exclude: this.$removeQueryArray(this.filter.id),
        filter_include: this.$removeQueryArray(this.filter.id)
      })
    } else {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.filter.id),
        filter_exclude: this.$unionQueryArray(this.filter.id),
        filter_include: this.$removeQueryArray(this.filter.id)
      })

      if (this.filter.id === 'diagram-exists') {
        analytics.diagramExistsFilterExclude.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId]
        })
      } else if (this.filter.id === 'flow-exists') {
        flowAnalytics.flowExistsFilterExclude.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    }
  }
}
