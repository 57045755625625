import * as analytics from '@icepanel/app-analytics'

export const flowStepCount: analytics.Property<number> = {
  displayName: 'Flow step count',
  name: 'flow_step_count',
  required: true,
  type: 'number'
}
export const flowStepOutgoingCount: analytics.Property<number> = {
  displayName: 'Flow step outgoing count',
  name: 'flow_step_outgoing_count',
  required: true,
  type: 'number'
}
export const flowStepReplyCount: analytics.Property<number> = {
  displayName: 'Flow step reply count',
  name: 'flow_step_reply_count',
  required: true,
  type: 'number'
}
export const flowStepSelfActionCount: analytics.Property<number> = {
  displayName: 'Flow step self action count',
  name: 'flow_step_self_action_count',
  required: true,
  type: 'number'
}
export const flowStepParallelPathCount: analytics.Property<number> = {
  displayName: 'Flow step parallel path count',
  name: 'flow_step_parallel_path_count',
  required: true,
  type: 'number'
}
export const flowStepAlternatePathCount: analytics.Property<number> = {
  displayName: 'Flow step alternate path count',
  name: 'flow_step_alternate_path_count',
  required: true,
  type: 'number'
}
